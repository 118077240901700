import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.last-articles-slider');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 'auto',
        watchOverflow: true,
      });
    });
  }
});
